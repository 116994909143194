import React from 'react'
import { Link } from 'react-router-dom'

export default function ThankOrder() {
  return (
      <div> 

      <div className='container my-5 '>

        <center>
          <img src={`/assets/images/success.png`} height={200} alt='success'/>
          <br></br>
        <h2> Thank You for your order.<Link to="/home"> Click here to Home</Link> </h2></center>
      </div>



    </div>
  )
}
