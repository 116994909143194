import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Footer extends Component {
    render() {
        return (

            <>
                <footer>
                    <div className="container-fluid">
                        <div className="footer-area">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="foot-logo">
                                        <img src={`/assets/images/logo.png`} alt="..." className="w-100 h-100" />
                                    </div>
                                    <div className="foot-info">
                                        Amogh Ayurved is an Online Store to buy 100% Natural Herbal Tablets in India. The best remedy for healthy life.
                                    </div>
                                    <div className="download-btn">
                                        <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.ved.amogh_ayurved">
                                            <img src={`/assets/images/google-play.png`} alt="..." className="w-100 h-100" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="footer-links">
                                        <div className="links-head">
                                            <span>Useful Links</span>
                                        </div>
                                        <div className="links-list">
                                            {/* <Link to="/#"><i className="fa-brands fa-pagelines"></i> &nbsp; Recommended</Link> */}
                                            <Link to="/about"><i className="fa-solid fa-building-wheat"></i> &nbsp; About Us</Link>
                                            <Link to="/remedies"><i className="fa-solid fa-pills"></i> &nbsp; Remedies</Link>
                                            <Link to="/privacy"><i className="fa fa-list-alt"></i> &nbsp; Privacy Policy</Link>
                                            <Link to="/terms"><i className="fa fa-tasks"></i> &nbsp; Terms & Conditions</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="footer-links">
                                        <div className="links-head">
                                            <span>Contact Us</span>
                                        </div>
                                        <div className="links-list">
                                            <div className="d-flex">
                                                <i className="fa-solid fa-signs-post">&nbsp; &nbsp;</i>
                                                Shiv Colony, Maharana Pratap, Nagar, Near MLB College, Jhansi, U.P - 284001
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <i className="fa-solid fa-phone">&nbsp; &nbsp;</i>
                                                <a href="tel:+917887246298">+91-7887246298, 8542847236</a>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <i className="fa-regular fa-paper-plane">&nbsp; &nbsp;</i>
                                                <a href="mailto:info@amokhaherbal.com">info@amokhaherbal.com</a>
                                            </div>
                                        </div>
                                        <div className="foot-social">
                                            <a href="/#"><i className="fa-brands fa-facebook-f"></i></a>
                                            <a href="/#"><i className="fa-brands fa-instagram"></i></a>
                                            <a href="/#"><i className="fa-brands fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="copyright">
                        Copyright © 2023 Amogh Ayurved . All Rights Reserved
                    </div>
                </footer>

            </>
        )
    }
}
