import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import ConstantUrl from '../util/ConstantUrl';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import axios from "axios";
import { CartContext } from '../context/CartContext';
export default function Navbar() {
    const URL = new ConstantUrl();
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState('')
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const cartstate = useContext(CartContext);
    const [namesAllType, setNamesAllType] = useState([]);

    const user = JSON.parse(localStorage.getItem("user"));
    const [carts, setCarts] = useState([]);
    const navigate = useNavigate();


    // useEffect(() => {
    //     async function fetchNameData() {
    //         try {
    //             const allNames = [];

    //             // Fetching all API responses in parallel
    //             const [teamsResponse, slidersResponse, categoriesResponse, brandsResponse, blogsResponse, homeLibraryResponse, articleResponse, herbResponse, productsResponse, bannersResponse] = await Promise.all([
    //                 axios.post(URL.teamsAll),
    //                 axios.post(URL.homeslidersAll),
    //                 axios.post(URL.categoryAll),
    //                 axios.post(URL.brandsAll),
    //                 axios.post(URL.blogsAll),
    //                 axios.post(URL.homeLibrary),
    //                 axios.post(URL.article),
    //                 axios.post(URL.herb),
    //                 axios.post(URL.productAll),
    //                 axios.post(URL.bannerAll),
    //             ]);

    //             // Function to add names from the response data
    //             const addNames = (data, key = 'name') => {
    //                 if (Array.isArray(data)) {
    //                     data.forEach(item => {
    //                         if (item[key]) {
    //                             allNames.push(item[key]);
    //                         }
    //                     });
    //                 }
    //             };

    //             // Extracting names from each response
    //             addNames(teamsResponse.data["data"]);
    //             addNames(slidersResponse.data["data"]);
    //             addNames(categoriesResponse.data["data"]);
    //             addNames(brandsResponse.data["data"]);
    //             addNames(blogsResponse.data["data"]);
    //             addNames(homeLibraryResponse.data["data"]);
    //             addNames(articleResponse.data["data"]);
    //             addNames(herbResponse.data["data"]);
    //             addNames(productsResponse.data["product"]);
    //             addNames(bannersResponse.data["data"]);

    //             // Logging the combined array of names
    //             console.log("All Names:", allNames);
    //             setNamesAllType(allNames)
    //             setCategories(allNames)
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         }
    //     }

    //     fetchNameData();
    // }, []);


    useEffect(() => {
        async function fetchNameData() {
            try {
                const allData = [];
                // Fetching all API responses in parallel
                const [teamsResponse, slidersResponse, categoriesResponse, brandsResponse, blogsResponse, homeLibraryResponse, articleResponse, herbResponse, productsResponse, bannersResponse] = await Promise.all([
                    axios.post(URL.teamsAll),
                    axios.post(URL.homeslidersAll),
                    axios.post(URL.categoryAll),
                    axios.post(URL.brandsAll),
                    axios.post(URL.blogsAll),
                    axios.post(URL.homeLibrary),
                    axios.post(URL.article),
                    axios.post(URL.herb),
                    axios.post(URL.productAll),
                    axios.post(URL.bannerAll),
                ]);

                // Function to add names, ids, and titles
                // const addData = (data, title) => {
                //     if (Array.isArray(data)) {
                //         data.forEach(item => {

                            
                //             if (item.name && item.id) {
                //                 allData.push({
                //                     name: item.name,
                //                     id: item.id,
                //                     title: title
                //                 });
                //             }
                //         });
                //     }
                // };

                const addData = (data, title) => {
                    if (Array.isArray(data)) {
                        data.forEach(item => {
                            // Check for 'name' or 'title' field
                            const nameOrTitle = item.name || item.title || item.brand_name;
                            if (nameOrTitle && item.id) {
                                allData.push({
                                    name: nameOrTitle,
                                    id: item.id,
                                    title: title
                                });
                            }
                        });
                    }
                };

                // Extracting data from each response with a title
                addData(teamsResponse.data["data"], "doctor-profile");
                // addData(slidersResponse.data["data"], "Slider");
                addData(categoriesResponse.data["data"], "product-list/category");
                addData(brandsResponse.data["data"], "product-list/brand");
                addData(blogsResponse.data["data"], "remedy-details");
                addData(homeLibraryResponse.data["data"], "HealthLibrabryDetails");
                addData(articleResponse.data["data"], "researchArticlesDetails");
                addData(herbResponse.data["data"], "herbsDetails");
                addData(productsResponse.data["product"], "product-details");
                // addData(bannersResponse.data["data"], "Banner");

                // Logging the combined array of data
                console.log("All Data:", allData);
                setNamesAllType(allData);
                setCategories(allData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        fetchNameData();
    }, []);
console.log(namesAllType,"all name of category")
    // useEffect(() => {
    //     // Fetch or define categories
    //     const fetchedCategories = [
    //         'Electronics',
    //         'Clothing',
    //         'Home Appliances',
    //         'Books',
    //         'Beauty Products',
    //         'Toys',
    //         "Gastritjs"
    //     ];
    //     setCategories(fetchedCategories);
    //     console.log("Categories loaded:", fetchedCategories);
    // }, []);
    // useEffect(() => {


    //     $(document).ready(function () {

    //         $(".mobile-menu").click(function () {

    //             $("#mobile-nav").animate({ right: "0" });


    //         });

    //         $(".close-mobile").click(function () {
    //             $("#mobile-nav").animate({ right: "-300px" });
    //         });

    //         $(".close-siderbar").click(function () {
    //             $("#mobile-nav").animate({ right: "-300px" });
    //         });

    //     });

    //     if (user !== null) {

    //         if (cartstate.cart !== null) {

    //             setCarts(cartstate.cart)

    //         }
    //     }


    // }, [cartstate.cart]);
    useEffect(() => {
        if (user !== null && cartstate.cart) {
            setCarts(cartstate.cart);
        }
    }, [cartstate.cart, user]);

    console.log("cartstate.cart:", cartstate.cart);
    console.log("carts:", carts);
    // useEffect(() => {
    //     handleCategoryClick();
    // }, []);

    // const handleSearchChange = (e) => {
    //     const value = e.target.value;
    //     setSearchText(value);
    //     console.log(value, "value of search bar")

    //     if (value.trim() === '') {
    //         setFilteredCategories([]);
    //         setDropdownVisible(false);
    //     } else {
    //         const filtered = categories.filter((category) =>
    //             category.toLowerCase().includes(value.toLowerCase())
    //         );
    //         console.log("Filtered categories:", filtered);
    //         setFilteredCategories(filtered);
    //         setDropdownVisible(true);
    //     }
    // };

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchText(value);
        console.log(value, "value of search bar");
        if (value.trim() === '') {
            setFilteredCategories([]);
            setDropdownVisible(false);
        } else {
            const filtered = categories.filter((category) =>
                category.name.toLowerCase().includes(value.toLowerCase())
            );
            console.log("Filtered categories:", filtered);
            setFilteredCategories(filtered);
            setDropdownVisible(true);
        }
    };


    // const handleCategoryClick = (category) => {
    //     setSearchText(category);
    //     setDropdownVisible(false);
    //     navigate(`/product-list/category/${category}`);
    // };
    // const handleCategoryClick = (category) => {
    //     setSearchText(category);
    //     setDropdownVisible(false);
    //     console.log("Navigating to category:", category);
    //     navigate(`/product-list/category/${category}`);
    // };
    const handleCategoryClick = (category) => {
        setSearchText(category.name);
        setCategory(category)
        localStorage.setItem("categoryId", category.id)
        setDropdownVisible(false);
        console.log("Navigating to category:", category);
        navigate(`/${category.title}/${category.id}`);
      
    };

    // const handleSearchSubmit = () => {
    //     navigate(`/product-list/search/${searchText || 'null'}`);
    // };
    // const handleSearchSubmit = () => {
    //     console.log("Search submitted for:", searchText);
    //     if (searchText.trim()) {
    //         navigate(`/product-list/search/${searchText}`);
    //         // setSearchText("")
    //     } else {
    //         toast.error("Please enter a search term.");
    //     }
    // };

    const handleSearchSubmit = () => {
        if (searchText.trim()) {
            const matchedCategory = categories.find(
                (category) => category.name.toLowerCase() === searchText.toLowerCase()
            );

            if (matchedCategory) {
                navigate(`/${matchedCategory.title}/${matchedCategory.id}`);
                // SetSearchText("");
            } else {
                // navigate(`/product-list/search/${searchText}`);
                navigate(`/`);
                toast.error("No match for this  search term.");
            }
            // setTimeout(() => {
            //     setSearchText("");
            // }, 10000);
        } else {
            toast.error("Please enter a search term.");
        }
    };
    // const submittosearch = (e) => {

    //     if (searchtext === null) {
    //         navigate('/product-list/allproduct/null', { replace: true });
    //     } else {
    //         navigate('/product-list/search/' + searchtext, { replace: true });
    //     }


    // }
    const logout = (e) => {
        localStorage.removeItem('user');
        cartstate.updateCart();
        navigate('/login', { replace: true });
        toast("Succesfully Logout.", { type: "success", theme: "dark" });

    }

    return (
        <>

            <ToastContainer />
            <header>
                <div className="header-top">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="contact-bar">
                                        <div className="mobile">
                                            <i className="fa-solid fa-phone"></i>
                                            &nbsp; +91 7887246298, 8542847236   &nbsp;   &nbsp;   &nbsp;
                                        </div>
                                        <div className="email">
                                            <i className="fa-regular fa-paper-plane"></i>
                                            &nbsp; info@amokhaherbal.com
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="social-bar">
                                        <a href="/#"><i className="fa-brands fa-facebook-f"></i></a>
                                        <a href="/#"><i className="fa-brands fa-instagram"></i></a>
                                        <a href="/#"><i className="fa-brands fa-linkedin-in"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-middle">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="header-container">
                                <div className="logo">
                                    <Link to="/"> <img src={`/assets/images/logo.png`} alt="..." className="w-100 h-100" /></Link>
                                </div>
                                <div className="nav-search-bar relative w-full max-w-lg mx-auto">
                                    <input type="text" name="search" value={searchText} placeholder="Search for Products here..."
                                        //  onChange={e => setSearchText(e.target.value)} 
                                        // className='hover:shadow-lg'
                                        onChange={handleSearchChange} onFocus={() => searchText && setDropdownVisible(true)}
                                        onBlur={() => setTimeout(() => setDropdownVisible(false), 200)}
                                        className="w-full px-4 py-2 border border-gray-300 rounded hover:shadow-lg focus:outline-none "
                                    />
                                    {/* <label role="button" onClick={submittosearch} htmlFor="search"> */}
                                    <label role="button" onClick={handleSearchSubmit} htmlFor="search"
                                        className="absolute top-1/2 right-1 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                                    >
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </label>
                                    {/* {dropdownVisible && (
                                        <div className="dropdown-menu">
                                            {filteredCategories.length > 0 ? (
                                                filteredCategories.map((category, index) => (
                                                    <div
                                                        key={index}
                                                        className="dropdown-item"
                                                        onClick={() => handleCategoryClick(category)}
                                                    >
                                                        {category}
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="dropdown-item">No categories found</div>
                                            )}
                                        </div>
                                    )} */}
                                    {dropdownVisible && (
                                        <div className="absolute left-0 top-full  w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto z-50">
                                            {filteredCategories.length > 0 ? (
                                                filteredCategories.map((category, index) => (
                                                    <div
                                                        key={index}
                                                        className="px-4 py-2 cursor-pointer hover:bg-gray-100 text-gray-700"
                                                        // onClick={() => handleCategoryClick(category)}
                                                        onMouseDown={() => handleCategoryClick(category)}
                                                    >
                                                        {category.name}
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="px-4 py-2 text-gray-500">No categories found</div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="mid-navs d-flex">
                                    {/* <div className="search-nav">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </div> */}
                                    {/* <div className="cart-wish wishlist-nav">
                                <a href="wishlist"><i className="fa-regular fa-heart"></i></a>
                                <span className="counter">5</span>
                            </div> */}

                                    <div className="cart-wish cart-nav">
                                        <Link to="/cart">
                                            <img src={`/assets/images/shopping-cart.png`} alt="..." className="shopping-cart" />
                                        </Link>
                                        <span className="counter">{carts.length !== 0 ? carts.productcount : 0}</span>
                                        {/* <span className="counter">
                                            {Array.isArray(carts) && carts.length !== 0
                                                ? carts.reduce((acc, item) => acc + (item.productcount || 0), 0)
                                                : 0}
                                        </span> */}
                                    </div>

                                    {/* <div className="cart-wish mobile-menu">
                                        <div>
                                            <i className="fa-solid fa-bars-staggered"></i>
                                        </div>
                                    </div> */}

                                    <div className="join">
                                        <div className="dropdown">
                                            <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                                                &nbsp; {user === null ? <>

                                                    <i className="fa-solid fa-user"></i>&nbsp;<span className="d-lg-inline d-md-inline d-none">Login | Register</span>


                                                </> : user.name}
                                            </button>
                                            <ul className="dropdown-block">
                                                {
                                                    user === null ?
                                                        <>    <li><Link className="dropdown-item" to="/login">Login</Link></li>
                                                            <li><Link className="dropdown-item" to="/register">Register</Link></li>
                                                        </> :
                                                        <>

                                                            <li><Link className="dropdown-item" to="/user-profile">My Profile</Link></li>
                                                            <li><Link className="dropdown-item" to="/changepassword">Change password</Link></li>
                                                            <li><span className="dropdown-item" role="button" onClick={logout}>Log Out</span></li>
                                                        </>
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-bottom">
                    {/* <div className="container-fluid">
                        <div className="header-container">
                            <div className="category-dropdown">
                                <div className="dropdown">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                                        Categories
                                    </button>
                                    <ul className="dropdown-menu">
                                        {categories.map(cat =>
                                            <li key={cat.id}><a className="dropdown-item" href="/#">{cat.name}</a></li>
                                        )}

                                    </ul>
                                </div>
                            </div>
                            <div className="bottom-navs">
                                <ul>
                                    <li><Link to="home"><i className="fa-solid fa-house-chimney"></i> &nbsp; Home</Link></li>
                                    <li><Link to="about"><i className="fa-solid fa-building-wheat"></i> &nbsp; About Us</Link></li>
                                    <li><Link to="find-doctors"><i className="fa-solid fa-user-doctor"></i> &nbsp; Doctors</Link></li>
                                    <li><Link to="remedies"><i className="fa-solid fa-pills"></i> &nbsp; Remedies</Link></li>
                                    <li><Link to="contact"><i className="fa-solid fa-square-phone"></i> &nbsp; Contact Us</Link></li>


                                </ul>
                            </div>
                        </div>
                    </div> */}
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>

            </header>

            {/* <!-- Search Popup --> */}
            {/* <div id="search-page" className={this.state.searchBar?'hidden':''} >
        <div className="search-block">
            <form action="#">
                <input type="text" name="search" id="search" placeholder="Search Here....."/>
                <label for="search"><i className="fa-solid fa-magnifying-glass"></i></label>
            </form>
        </div>
        <div className="close-search" onClick={this.closeSearch}>
            <i className="fa-solid fa-xmark"></i>
        </div>
    </div> */}


            {/* <!-- Sidebar Navigation --> */}
            <div id="mobile-nav" >
                <div className="mobile-head">
                    <div className="logo">
                        <img src="/assets/images/logo.png" alt="..." className="w-100 h-100" />
                    </div>
                    <div role="button" className="close-mobile">
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                </div>
                <div className="mobile-body">
                    <div className="mobile-navs close-siderbar">
                        {/* <div className="mobile-nav-search">
                            <input type="text" name="search" placeholder="Search for Products here..."></input>
                            <label for="search"><i className="fa-solid fa-magnifying-glass"></i></label>
                        </div> */}
                        <div>
                            <Link to="/"><i className="fa-solid fa-house-chimney"></i> &nbsp; Home</Link>
                        </div>
                        {/* <div>
                            <a href="wishlist"><i className="fa-regular fa-heart"></i> &nbsp; Wishlist</a>
                        </div>
                        <div>
                            <a href="cart">
                                <img src={`/assets/images/shopping-cart.png`} alt="..." className="shopping-cart" />
                                &nbsp; Cart
                            </a>
                        </div> */}
                        <div className='close-siderbar'>
                            <Link to="/about"><i className="fa-solid fa-building-wheat"></i> &nbsp; About Us</Link>
                        </div>
                        <div >
                            <Link to="/find-doctors"><i className="fa-solid fa-user-doctor"></i> &nbsp; Doctors</Link>
                        </div>
                        <div>
                            <Link to="/remedies"><i className="fa-solid fa-pills"></i> &nbsp; Remedies</Link>
                        </div>
                        <div>
                            <Link to="/contact"><i className="fa-solid fa-square-phone"></i> &nbsp; Contact Us</Link>
                        </div>
                        <div>
                            <a href="mailto:info@amokhaherbal.com"><i className="fa-regular fa-paper-plane"></i> &nbsp;
                                info@amokhaherbal.com</a>
                        </div>
                        <div>
                            <a href="tel:+917887246298,"><i className="fa-solid fa-phone"></i> &nbsp; +91 7887246298</a>
                        </div>

                        {
                            user === null ?
                                <>

                                    <div>
                                        <Link to="/login"><i className="fa-solid fa-arrow-right-to-bracket"></i> &nbsp; Login</Link>
                                    </div>
                                    <div>
                                        <Link to="/register"><i className="fa-solid fa-user-plus"></i> &nbsp; Register</Link>
                                    </div>

                                </> :
                                <>
                                    <div>
                                        <Link to="/user-profile"><i className="fa-regular fa-circle-user"></i> &nbsp; My Profile</Link>
                                    </div>
                                    <div>
                                        <Link to="/changepassword"><i className="fa-regular fa-circle-user"></i> &nbsp; Change Password</Link>
                                    </div>
                                    <div onClick={logout}>
                                        <a href="/#"><i className="fa-solid fa-arrow-right-from-bracket"></i> &nbsp; Log Out</a>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </>
    )

}
