import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CartContext } from "../context/CartContext";
import ConstantUrl from "../util/ConstantUrl";
import { Parser } from "html-to-react";

export default function ProductList() {
    const cartstate = useContext(CartContext);
    const URL = new ConstantUrl();
    var user = JSON.parse(localStorage.getItem("user"));
    const [categories, setCategorie] = useState([]);
    const [subTitle, setSubTitle] = useState("");
    const [brands, setBrands] = useState([]);
    const navigate = useNavigate();
    let { type } = useParams();
    let { id } = useParams();
    console.log(type, "type")
    console.log(id, "id")
    const [products, setProduct] = useState([]);

    useEffect(() => {
        async function fetchdata() {
            if (type === "category") {
                await axios
                    .post(URL.getProductByCategoryId, { category_id: id })
                    .then((response) => {
                        setProduct(response.data["product"]);
                        setCategorie(response.data.category)
                        setSubTitle(response.data.category?.subtitle?.replace(/<p><br\s?\/?><\/p>/g, '') // Removes empty <p><br/></p> tags
                            .replace(/<p><\/p>/g, '')           // Removes empty <p></p> tags
                            .replace(/<br\s?\/?>/g, ''));
                        console.log(response.data.category, "category data")
                        console.log(response, "product data")
                    });
                // console.log(response.data["product"])
            } else if (type === "brand") {
                await axios
                    .post(URL.getProductByBrandId, { brand_id: id })
                    .then((response) => {
                        setProduct(response.data["product"]);
                    });
            } else if (type === "allproduct") {
                await axios.post(URL.productAll).then((response) => {
                    setProduct(response.data["product"]);
                    console.log(response.data["product"]);
                });
            } else if (type === "search") {
                await axios.post(URL.searchProduct + id).then((response) => {
                    setProduct(response.data["data"]);
                });
            }
        }

        fetchdata();
    }, [type, id]);

    const [variant_id, setVariantId] = useState();
    const [product_id, setProductId] = useState();

    const onchangeoption = (id) => (event) => {
        setProductId(id);
        setVariantId(event.target.value);
    };

    function stripHtmlTags(html) {
        const doc = new DOMParser().parseFromString(html, "text/html");
        return doc.body.textContent || "";
    }

    const submitEvent = (id, v_id) => (event) => {
        event.preventDefault();
        if (user === null) {
            navigate("/login", { replace: true });
            toast("Login First", { type: "error", theme: "dark" });
        }

        axios
            .post(URL.cartAdd, {
                product_id: id,
                variant_id: variant_id ? (product_id === id ? variant_id : v_id) : v_id,
                quantity: 1,
                user_id: user.id,
            })
            .then((response) => {
                cartstate.updateCart();

                if (response.data["error"]) {
                    toast(response.data["message"], { type: "error", theme: "dark" });
                } else {
                    toast("Product added in cart", { type: "success", theme: "dark" });
                }
            });
    };

    console.log("products", products)

    return (
        <>
            {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
            <section id="breadcrumb">
                <div className="container-fluid">
                    <div className="container">
                        <div className="breadcrumb-bar">
                            <div>
                                <Link to="/home">Home</Link>
                            </div>
                            <div>
                                &nbsp; <i className="fa-solid fa-minus"></i> &nbsp;
                            </div>
                            <div>Products</div>
                        </div>
                    </div>
                </div>
                <hr className="m-0" />
            </section>

            {/* {{-- =============================================
                PRODUCT LIST AREA
    ============================================  --}} */}
            <section id="product-list-area">
                <div className="section-gap">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="mb-4 gap-4 flex flex-col">
                                <h1 className="text-3xl">{categories.name}:</h1>
                                <p>
                                    <span dangerouslySetInnerHTML={{ __html: subTitle }} />
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="product-grid">
                                        {products.length !== 0 ? (
                                            <div className="row">
                                                {products.map((product) => (
                                                    <div className="col-lg-3 col-md-4 col-sm-6">
                                                        <div className="product-card">

                                                            <div className="product-img">
                                                                <Link
                                                                    to={`/product-details/${product.id}`}
                                                                    className="d-block w-100 h-100"
                                                                >
                                                                    <img
                                                                        src={URL.productsPhotoUrl + product.image}
                                                                        alt="..."
                                                                        className="w-100 h-100"
                                                                    />
                                                                </Link>
                                                            </div>
                                                            <div className="product-info">
                                                                <div>
                                                                    <div className="product-name text-truncate">
                                                                        {product.name}
                                                                    </div>
                                                                    <div className="price">
                                                                        Rs.{" "}
                                                                        {
                                                                            product.product_variant.length !== 0 && product.product_variant[0]
                                                                                .discounted_price
                                                                        }
                                                                        <del>
                                                                            Rs.{product.product_variant.length !== 0 && product.product_variant[0].price}
                                                                        </del>
                                                                    </div>
                                                                </div>
                                                                <div className="variant-bar">
                                                                    <select
                                                                        name="variant"
                                                                        onChange={onchangeoption(product.id)}
                                                                        className="w-100"
                                                                    >
                                                                        {product.product_variant.map((pv) => (
                                                                            <option value={pv.id}>
                                                                                {pv.qty} {pv.uname} - Rs.{" "}
                                                                                {pv.discounted_price}{" "}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="cart-btn">
                                                                    <button
                                                                        onClick={submitEvent(
                                                                            product?.id,
                                                                            product.product_variant[0]?.id
                                                                        )}
                                                                    >
                                                                        {" "}
                                                                        <img
                                                                            src={`/assets/images/shopping-cart.png`}
                                                                            alt="..."
                                                                            className="shopping-cart w-100 h-100"
                                                                        />{" "}
                                                                        &nbsp; Add{" "}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {/* <button className="wishlist-btn"><i className="fa-solid fa-heart"></i></button> */}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <>
                                                <center>
                                                    <h5> Product not found</h5>
                                                    {/* <h6>Product Comes from here</h6> */}
                                                </center>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
