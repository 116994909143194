import axios from 'axios';
import React, {  useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import ConstantUrl from '../util/ConstantUrl';

export default function ListProfile() {
 
    const [name,setName] = useState();
    const [motherName, setMotherName] = useState();
    const [fatherName, setFatherName] = useState();
    const [deparetment, setDepartment] = useState();
    const [dob, setDob] = useState();
    const [gender, setGender] = useState();
    const [marital, setMarital] = useState();
    const [nationality, setNationality] = useState();
    const [qualification, setQualification] = useState();
    const [clinic, setClinic] = useState();
    const [city, setCity] = useState();
    const [pincode, setPinCode] = useState();
    const [mobile, setMobile] = useState();
    const [email, setEmail] = useState();
    const [url, setUrl] = useState();
    const [consultaiontime, setConsulationTime] = useState();
    const [consulationfee, setConsultaionFee] = useState();
    const [paymentnumber, setPaymentNumber] = useState();
    const [comments, setComments] = useState();
    const [document, setDocument] = useState(null);
    const [image,setImage] = useState(null);


    const navigate = useNavigate();
    const URL = new ConstantUrl();
    const onSubmit= async (e) =>{

         const formData = new FormData();  
            formData.append("name", name);
            formData.append("email", email);
            formData.append("department", deparetment);
            formData.append("mother_name", motherName);
            formData.append("father_name", fatherName);
            formData.append("dob", dob);
            formData.append("gender", gender);
            formData.append("marital_status", marital);
            formData.append("nationality", nationality);
            formData.append("qualification", qualification);
            formData.append("clinic_address", clinic);
            formData.append("city", city);
            formData.append("pincode", pincode);
            formData.append("mobile_number", mobile);
            formData.append("email_id", email);
            formData.append("website_url", url);
            formData.append("consultation_time", consultaiontime);
            formData.append("consultation_fee", consulationfee);
            formData.append("payment_number", paymentnumber);
            formData.append("comments", comments);
        formData.append("image", image);
        formData.append("document", document);

             e.preventDefault();
        await axios.post(URL.addDoctor,
          formData
        ).then((response) => {
         
            if (!response.data['error']) {
                  const data = response.data['data'];
                navigate('/find-doctors', { replace: true });
                toast("Profile Sumitted successfully. We will contact soon.",{type:"success",theme:"dark"})
            } else {
                 toast("Something went wrong.",{type:"success",theme:"dark"})
            }
                
                }
            );
    



    }

    return (
        <>
          {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
    <section id="breadcrumb">
        <div className="container-fluid">
            <div className="container">
                <div className="breadcrumb-bar">
                    <div><Link to="/home">Home</Link></div>
                    <div>&nbsp; <i className="fa-solid fa-minus"></i> &nbsp;</div>
                    <div>List Your Profile</div>
                </div>
            </div>
        </div>
        <hr className="m-0"/>
    </section>

    {/* {{-- =============================================
                    LISTING PROFILE
    ============================================  --}} */}
    <section id="listing-profile">
        <div className="section-gap">
            <div className="container-fluid">
                <div className="container">
                    <div className="list-profile-form">
                        <form onSubmit={onSubmit}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-block">
                                        <div className="form-head">Doctor Listing</div>
                                        <div className="form-fields">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="group-input">
                                                        <label htmlFor="name">Full Name</label>
                                                        <input type="text" name="name" id="name"
                                                            placeholder="..." onChange={e => setName(e.target.value)} required/>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="group-input">
                                                        <label htmlFor="name">Mother's Name</label>
                                                        <input type="text" onChange={e => setMotherName(e.target.value)} name="mother_name" id="m-name"
                                                            placeholder="..."/>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="group-input">
                                                        <label htmlFor="f-name">Father's Name</label>
                                                        <input type="text" onChange={e => setFatherName(e.target.value)} name="father_name" id="f-name"
                                                            placeholder="..."/>
                                                    </div>
                                                        </div>
                                                <div className="col-md-6">
                                                    <div className="group-input">
                                                        <label htmlFor="label">Department</label>
                                                        <input type="text" onChange={e => setDepartment(e.target.value)} name="department" id="label"
                                                            placeholder="..." />
                                                    </div>
                                                </div> 
                                                <div className="col-md-6">
                                                    <div className="group-input">
                                                        <label htmlFor="dob">Date of Birth</label>
                                                        <input type="date" onChange={e => setDob(e.target.value)} name="dob" id="dob"
                                                            placeholder="..."/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="group-input">
                                                        <label htmlFor="gender">Gender</label>
                                                        <select required onChange={e => setGender(e.target.value)} name="gender" id="gender">
                                                            <option selected>---</option>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="group-input">
                                                        <label htmlFor="marital">Marital Status</label>
                                                        <select required onChange={e => setMarital(e.target.value)}  name="marital" id="marital">
                                                            <option selected>---</option>
                                                            <option value="married">Married</option>
                                                            <option value="unmarried">Unmarried</option>
                                                            <option value="divorced">Divorced</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="group-input">
                                                        <label htmlFor="nationality">Nationality</label>
                                                        <input  onChange={e => setNationality(e.target.value)} type="text" name="nationality" id="nationality"
                                                            placeholder="..."/>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="group-input">
                                                        <label htmlFor="qualification">Qualification</label>
                                                        <input onChange={e => setQualification(e.target.value)} type="text" name="qualification" id="qualification"
                                                            placeholder="..."/>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-block">
                                        <div className="form-head">Contact Details</div>
                                        <div className="form-fields">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="group-input">
                                                        <label htmlFor="clinic">Clinic/Hospital Address</label>
                                                        <input onChange={e => setClinic(e.target.value)} type="text" name="clinic" id="clinic"
                                                            placeholder="..."/>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="group-input">
                                                        <label htmlFor="city-town">City/Town</label>
                                                        <input onChange={e => setCity(e.target.value)} type="text" name="city" id="city-town"
                                                            placeholder="..."/>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="group-input">
                                                        <label htmlFor="pin">Pincode</label>
                                                        <input onChange={e => setPinCode(e.target.value)} type="number" name="pin" id="pin"
                                                            placeholder="..."/>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="group-input">
                                                        <label htmlFor="mobile">Mobile Number</label>
                                                        <input required onChange={e => setMobile(e.target.value)} type="number" name="mobile" id="mobile"
                                                            placeholder="..." />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="group-input">
                                                        <label htmlFor="email">E-Mail</label>
                                                        <input onChange={e => setEmail(e.target.value)} type="email" name="email" id="email"
                                                            placeholder="..." />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="group-input">
                                                        <label htmlFor="url">Website/Blog URL</label>
                                                        <input onChange={e => setUrl(e.target.value)} type="text" name="url" id="url"
                                                            placeholder="..."/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-block">
                                        <div className="form-head">Consultation Details</div>
                                        <div className="form-fields">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="group-input">
                                                        <label htmlFor="p-number">GPay/PhonePe/Paytm Number</label>
                                                        <input onChange={e => setPaymentNumber(e.target.value)} type="number" name="paymentnumber" id="p-number"
                                                            placeholder="..."/>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="group-input">
                                                        <label htmlFor="number">Consultation Fee</label>
                                                        <input onChange={e => setConsultaionFee(e.target.value)} type="number" name="consulationfee" id="number"
                                                            placeholder="..."/>
                                                    </div>
                                                        </div>
                                                          <div className="col-6">
                                                    <div className="group-input">
                                                        <label htmlFor="time">Consultation Time</label>
                                                        <input onChange={e => setConsulationTime(e.target.value)} type="time" name="consultaiontime" id="time"
                                                            placeholder="..."/>
                                                    </div>
                                                </div>
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-block">
                                        <div className="form-head">Upload Your Documents</div>
                                        <div className="form-fields">
                                            <div className="row">
                            
                                                <div className="col-12">
                                                    <div className="group-input">
                                                        <label htmlFor="registration">Doctor Registration</label>
                                                        <input  type="file"  onChange={e => setDocument(e.target.files[0])} name="registration" id="registration"
                                                            placeholder="..."/>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="group-input">
                                                        <label htmlFor="photo">Passport Size Photo</label>
                                                        <input type="file"  onChange={e => setImage(e.target.files[0])} name="photo" id="photo"
                                                            placeholder="..."/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        </div>
                                        
                            <div className="col-lg-12">
                                    <div className="form-block">
                                        <div className="form-fields">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="group-input">
                                                        <label htmlFor="p-number">Comments</label>
                                                        <textarea  onChange={e => setComments(e.target.value)}  rows="3" name="comments"
                                                            placeholder="..."/>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>  

                                <div className="col-12">
                                    <div className="group-input">
                                        <input type="submit" value="Send Application" className="submit"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
            
        
        </>
    )

}
