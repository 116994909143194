import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Parser } from 'html-to-react'
import ConstantUrl from '../util/ConstantUrl';
export default function Terms() {

    const [terms, setTerms] = useState(null);
    const URL =new ConstantUrl();
    useEffect(() => {

        async function fetchdata() {

              await  axios.post(URL.settingData,{data:'termsconditions'}).then((response) => {
        
           const termsdata = response.data['data'];
      
         setTerms(termsdata[0]);
       
              });

         
        }

        fetchdata();

    }, []);

    if (terms !== null) {
        
        
  return (
         <>
         {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
    <section id="breadcrumb">
        <div className="container-fluid">
            <div className="container">
                <div className="breadcrumb-bar">
                    <div><Link to="/home">Home</Link></div>
                    <div>&nbsp; <i className="fa-solid fa-minus"></i> &nbsp;</div>
                    <div>Terms & Conditions</div>
                </div>
            </div>
        </div>
        <hr className="m-0"/>
    </section>
{/* 
    {{-- =============================================
                    FIND DOCTORS AREA
    ============================================  --}} */}
    <section id="doctors-area">
        <div className="section-gap">
            <div className="container-fluid">
                <div className="container">
                   
                       {Parser().parse(terms.value)}   
                   
                </div>
            </div>
        </div>
    </section>
            
        </>
  )

    }

}
