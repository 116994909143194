import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class NoPage extends Component {
  render() {
    return (
      <>
     <section id="breadcrumb">
        <div className="container-fluid">
            <div className="container">
                <div className="breadcrumb-bar">
                    <div><Link to="/home">Home</Link></div>
                    <div>&nbsp; <i className="fa-solid fa-minus"></i> &nbsp;</div>
                    <div>No page</div>
                </div>
            </div>
        </div>
        <hr className="m-0"/>
        </section>
        
         <section id="doc-profile-area">
        <div className="section-gap">
            <div className="container-fluid">
                <div className="container">

                  <center><h3>No Page Found</h3> </center>
                
                </div>
            </div>
        </div>
    </section>
    </>
    )
  }
}
