import axios from 'axios';
import React, { Component } from 'react'
import ConstantUrl from '../util/ConstantUrl';
import { Parser } from 'html-to-react'
import { Link } from 'react-router-dom';

export default class AboutUs extends Component {

        state = {
        aboutdata:[]
        };
        

   async componentDidMount() {
      
         const URL = new ConstantUrl();
        
      await  axios.post(URL.settingData,{data:'aboutus'}).then((response) => {
            
           const aboutdata = response.data['data'];
        //  console.warn(aboutdata);
          this.setState({ aboutdata:aboutdata[0] });
       
    });

        
    }


  render() {
    return (
        <>
        {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
    <section id="breadcrumb">
        <div className="container-fluid">
            <div className="container">
                <div className="breadcrumb-bar">
                    <div><Link to="/home">Home</Link></div>
                    <div>&nbsp; <i className="fa-solid fa-minus"></i> &nbsp;</div>
                    <div>About Us</div>
                </div>
            </div>
        </div>
        <hr className="m-0"/>
    </section>
{/* 
    {{-- =============================================
                    ABOUT AREA
    ============================================  --}} */}
    <section id="about-area">
        <div className="section-gap">
            <div className="container-fluid">
                <div className="container">
                    <div className="about-amogh">
                        <div className="row">
                      
                            <div className="col-lg-12  mt-5">
                                <div className="about-content">
                                    {/* <div className="about-logo">
                                        <img src="assets/images/logo.png" alt="..." className="w-100 h-100"/>
                                    </div> */}
                                    <div className="about-head">
                                        <span>About Amogh Ayurved</span>
                                    </div>
                                    <div className="about-content">
                                        {Parser().parse(this.state.aboutdata.value)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 
                </div>
            </div>
        </div>
    </section>
        </>
    )
  }
}
