import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ConstantUrl from "../util/ConstantUrl";
import { Parser } from "html-to-react";

export default function HealthLibrabryDetails() {
  let { id } = useParams();
  const URL = new ConstantUrl();
  const [research, setResearch] = useState([]);
  const [article, setArticle] = useState([]);

  async function fetchData() {
    await axios.post(URL.articleDetails, { articles_id: id }).then((response) => {
      const research = response.data["data"];
      console.warn(research);
      console.log("research",research)
      setResearch(research);
    });

    await axios.post(URL.article).then((response) => {
      const articledata = response.data["data"];
      setArticle(articledata);
    });
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <>
      {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
      <section id="breadcrumb">
        <div className="container-fluid">
          <div className="container">
            <div className="breadcrumb-bar">
              <div>
                <Link to="/home">Home</Link>
              </div>
              <div>
                &nbsp; <i className="fa-solid fa-minus"></i> &nbsp;
              </div>
              <div>Research Articles Details</div>
            </div>
          </div>
        </div>
        <hr className="m-0" />
      </section>

      {/* {{-- =============================================
                REMEDY DETAILS AREA
    ============================================  --}} */}
      <section id="remedy-detail-area">
        <div className="section-gap">
          <div className="container-fluid">
            <div className="container">
              <div className="block">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="current-remedy">
                      <div className="remedy-img">
                        <img
                          src={research.image1}
                          alt="..."
                          className="w-100 h-100"
                        />
                      </div>
                      <div className="remedy-head text-truncate">
                        {research.title}
                      </div>
                      <div className="remedy-details">
                        {Parser().parse(research.details)}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="recent-remedy">
                      <div className="head">
                        <span>Recent Research Articles</span>
                      </div>
                      <div className="remedy-grid">
                        {article.map((articledata) => (
                          <Link to={`/remedy-details/${articledata.id}`}>
                            <div className="remedy">
                              <div className="remedy-img">
                                <img
                                  src={articledata.image1}
                                  alt="..."
                                  className="w-100 h-100"
                                />
                              </div>
                              <div className="remedy-detail">
                                <div className="remedy-name">
                                  {articledata.title}
                                </div>
                                <div className="remedy-desc">
                                  {Parser().parse(articledata.details)}
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
