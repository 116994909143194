import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ConstantUrl from "../util/ConstantUrl";

export default function DoctorProfile() {
  let { id } = useParams();
  const URL = new ConstantUrl();
  const [doctor, setDoctor] = useState([]);

  async function fetchData() {
    await axios.post(URL.doctorDetails, { id: id }).then((response) => {
      const doctor = response.data["data"];
      console.warn(doctor);
      setDoctor(doctor);
    });
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <>
      {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
      <section id="breadcrumb">
        <div className="container-fluid">
          <div className="container">
            <div className="breadcrumb-bar">
              <div>
                <Link to="/home">Home</Link>
              </div>
              <div>
                &nbsp; <i className="fa-solid fa-minus"></i> &nbsp;
              </div>
              <div>Doctor Profile</div>
            </div>
          </div>
        </div>
        <hr className="m-0" />
      </section>

      {/* {{-- =============================================
                    DOCTOR PROFILE AREA
    ============================================  --}} */}
      <section id="doc-profile-area">
        <div className="section-gap">
          <div className="container-fluid">
            <div className="container">
              <div className="doc-name">
                {doctor.name}
                <div className="doc-deg">
                  {/* (MBBS, PhD) */}
                  {doctor.department}
                </div>
              </div>
              <div className="doc-info-block">
                <div className="doc-img">
                  <img
                    src={URL.teamsPhotoUrl + doctor.image}
                    alt="..."
                    className="w-100 h-100"
                  />
                </div>
                <div className="doc-details">
                  <div className="info-bar">
                    <div className="info-head">Clinic </div>
                    <div>&nbsp; : &nbsp;</div>
                    <div className="info-content flex-fill">
                      {" "}
                      {doctor.clinic_address
                        ? doctor.clinic_address
                        : "Not Available"}
                    </div>
                  </div>
                  <div className="info-bar">
                    <div className="info-head">Consultation Time </div>
                    <div>&nbsp; : &nbsp;</div>
                    <div className="info-content flex-fill">
                      {doctor.consultation_time
                        ? doctor.consultation_time
                        : "Not Available"}
                    </div>
                  </div>
                  <div className="info-bar">
                    <div className="info-head">Consultation Fee </div>
                    <div>&nbsp; : &nbsp;</div>
                    <div className="info-content flex-fill">
                      {doctor.consultation_fee
                        ? doctor.consultation_fee
                        : "Not Available"}
                    </div>
                  </div>
                  <div className="info-bar">
                    <div className="info-head">Mobile Number </div>
                    <div>&nbsp; : &nbsp;</div>
                    <div className="info-content flex-fill">
                      {doctor.mobile_number
                        ? doctor.mobile_number
                        : "Not Available"}
                    </div>
                  </div>
                  <div className="info-bar">
                    <div className="info-head">E-Mail ID </div>
                    <div>&nbsp; : &nbsp;</div>
                    <div className="info-content flex-fill">
                      {" "}
                      {doctor.email_id ? doctor.email_id : "Not Available"}
                    </div>
                  </div>
                  <div className="info-bar">
                    <div className="info-head">Website/Blog URL </div>
                    <div>&nbsp; : &nbsp;</div>
                    <div className="info-content flex-fill">
                      <a href="www.ruedjfdwfcvfh.com">
                        {doctor.website_url
                          ? doctor.website_url
                          : "Not Available"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
