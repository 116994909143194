import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ConstantUrl from "../util/ConstantUrl";
import { Parser } from "html-to-react";

export default function HealthLibrabryDetails() {
  let { id } = useParams();
  const URL = new ConstantUrl();
  const [health, setHealth] = useState([]);
  const [library, setLibrary] = useState([]);

  async function fetchData() {
    await axios.post(URL.healthDetails, {library_id: id }).then((response) => {
      const healthdata = response.data["data"];
      console.warn(healthdata);
      console.log("healthdata",healthdata)
      setHealth(healthdata);
    });

    await axios.post(URL.homeLibrary).then((response) => {
      const homeLibrarydata = response.data["data"];
      setLibrary(homeLibrarydata);
    });
  }

  useEffect(() => {
    fetchData();
  }, [id]);


  return (
    <>
      {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
      <section id="breadcrumb">
        <div className="container-fluid">
          <div className="container">
            <div className="breadcrumb-bar">
              <div>
                <Link to="/home">Home</Link>
              </div>
              <div>
                &nbsp; <i className="fa-solid fa-minus"></i> &nbsp;
              </div>
              <div>Health Library Details</div>
            </div>
          </div>
        </div>
        <hr className="m-0" />
      </section>

      {/* {{-- =============================================
                REMEDY DETAILS AREA
    ============================================  --}} */}
      <section id="remedy-detail-area">
        <div className="section-gap">
          <div className="container-fluid">
            <div className="container">
              <div className="block">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="current-remedy">
                      <div className="remedy-img">
                        <img
                          src={health.image}
                          alt="..."
                          className="w-100 h-100"
                        />
                      </div>
                      <div className="remedy-head text-truncate">
                        {health.name}
                      </div>
                      <div className="remedy-details">
                        {Parser().parse(health.des)}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="recent-remedy">
                      <div className="head">
                        <span>Recent Healrh</span>
                      </div>
                      <div className="remedy-grid">
                        {library.map((healthdata) => (
                           
                          <Link to={`/remedy-details/${healthdata.id}`}>
                            <div className="remedy">
                              <div className="remedy-img">
                                <img
                                  src={URL.blogsPhotoUrl + healthdata.image}
                                  alt="..."
                                  className="w-100 h-100"
                                />
                              </div>
                              <div className="remedy-detail">
                                <div className="remedy-name">{healthdata.name}</div>
                                <div className="remedy-desc">
                                  {Parser().parse(healthdata.des)}
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
