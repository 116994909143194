import axios from "axios";
import { Parser } from "html-to-react";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ConstantUrl from "../util/ConstantUrl";
import OwlCarousel from "react-owl-carousel";
import { toast } from "react-toastify";
import { CartContext } from "../context/CartContext";

export default function ProductDetails() {
  const navigate = useNavigate();
  var user = JSON.parse(localStorage.getItem("user"));

  const cartState = useContext(CartContext);

  let { id } = useParams();
  const URL = new ConstantUrl();
  const [product, setProduct] = useState([]);

  async function fetchData() {
    await axios.post(URL.productDetails, { id: id }).then((response) => {
      const data = response.data["product"];
      console.warn(data);
      setProduct(data);
    });
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  const [variant_id, setVariantId] = useState();
  const [quantity, setQuantity] = useState(1);

  const submitEvent = (event) => {
    event.preventDefault();

    if (user === null) {
      navigate("/login", { replace: true });
    }

    axios
      .post(URL.cartAdd, {
        product_id: product.id,
        variant_id: variant_id ? variant_id : product.product_variant[0].id,
        quantity: quantity,
        user_id: user.id,
      })
      .then((response) => {
        cartState.updateCart();

        if (response.data["error"]) {
          toast(response.data["message"], { type: "error", theme: "dark" });
        } else {
          toast("Product added in cart", { type: "success", theme: "dark" });
        }
      });
  };

  if (product.name) {
    return (
      <>
        {/* 
{{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
        <section id="breadcrumb">
          <div className="container-fluid">
            <div className="container">
              <div className="breadcrumb-bar">
                <div>
                  <Link to="/home">Home</Link>
                </div>
                <div>
                  &nbsp; <i className="fa-solid fa-minus"></i> &nbsp;
                </div>
                <div>Product Details</div>
              </div>
            </div>
          </div>
          <hr className="m-0" />
        </section>

        {/* {{-- =============================================
                PRODUCT DETAIL AREA
    ============================================  --}} */}
        <section id="product-detail-area">
          <div className="section-gap">
            <div className="container-fluid">
              <div className="container">
                <div className="product-overview">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="product-images">
                        <div className="image-slider">
                          <OwlCarousel
                            items={1}
                            className="owl-theme"
                            loop
                            nav
                            margin={8}
                          >
                            <div>
                              {" "}
                              {product.image ? (
                                <div className="product-img">
                                  <img
                                    src={URL.productsPhotoUrl + product.image}
                                    alt="..."
                                    className="w-100 h-100"
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            {product.other_image
                              ? JSON.parse(product.other_image).map((data) => (
                                  <div>
                                    <div className="product-img">
                                      <img
                                        src={URL.productsPhotoUrl + data}
                                        alt="..."
                                        className="w-100 h-100"
                                      />
                                    </div>
                                  </div>
                                ))
                              : ""}
                          </OwlCarousel>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="product-details">
                        <div className="product-name">{product.name}</div>
                        <div className="mb-3">
                          <small>{product.short_desc}</small>
                        </div>
                        <div className="product-price">
                          Rs. {product.product_variant[0].discounted_price}{" "}
                          <del>Rs.{product.product_variant[0].price}</del>
                        </div>
                        <div className="variant-bar">
                          <select
                            name="variant"
                            onChange={(e) => setVariantId(e.target.value)}
                            className="w-100"
                          >
                            {product.product_variant.map((pv) => (
                              <option key={pv.id} value={pv.id}>
                                {pv.qty} {pv.uname} - Rs. {pv.discounted_price}{" "}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="buttons">
                          <form onSubmit={submitEvent}>
                            <button>
                              <i className="fa-solid fa-bag-shopping"></i>{" "}
                              &nbsp; Add to Cart
                            </button>
                            {/* <button>
                                            <i className="fa-regular fa-heart"></i> &nbsp; Add to Wishlist
                                        </button> */}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product-description">
                  <div className="head">
                    <div>Product Description</div>
                  </div>
                  <div className="content">
                    {Parser().parse(product.description)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
