import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ConstantUrl from '../util/ConstantUrl';

export default class FindDoctor extends Component {



        constructor(props) {
        super(props);
            this.state = {
                teams: [],
                searchtext:''
            };
           
            this.URL = new ConstantUrl()
   
    }

  async componentDidMount() {

        
     const URL = new ConstantUrl();
        
      await  axios.post(URL.teamsAll).then((response) => {
            const teams = response.data['data'];
       
          this.setState({ teams });
    });

        
    }

    handleChange = (e) =>{
    
    this.setState({ searchtext: e.target.value });
  
           axios.post(this.URL.searchTeam+e.target.value).then((response) => {
            const teams = response.data['data'];
          this.setState({ teams });
    });
        
  };


    render() {
      
        return (
        
            <>
         {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
    <section id="breadcrumb">
        <div className="container-fluid">
            <div className="container">
                <div className="breadcrumb-bar">
                    <div><Link to="/home">Home</Link></div>
                    <div>&nbsp; <i className="fa-solid fa-minus"></i> &nbsp;</div>
                    <div>Doctors</div>
                </div>
            </div>
        </div>
        <hr className="m-0"/>
    </section>
{/* 
    {{-- =============================================
                    FIND DOCTORS AREA
    ============================================  --}} */}
    <section id="doctors-area">
        <div className="section-gap">
            <div className="container-fluid">
                <div className="container">
                    <div className="add-profile-btn">
                        <div>
                            Want to become Doctor at Amogh Ayurved?
                            <Link to="/list-profile">Add Your Profile</Link>
                        </div>
                    </div>
                    <div className="search-bar">
                        <form action="#">
                            <input type="text" name="search" id="search"  onChange={this.handleChange} placeholder="Search From Our Doctors....."/>
                            <label htmlFor="search"><i className="fa-solid fa-magnifying-glass"></i></label>
                        </form>
                    </div>
                            <div className="doc-profile-grid">
                                {this.state.teams.map(team =>
                                    
                                
                        <div className="doc-profile-card">
                            <div className="doc-img">
                                            <Link to={`/doctor-profile/${team.id}`}>
                                    <img src={team.image? this.URL.teamsPhotoUrl+team.image:'assets/images/doctor/avatar.jpg'} alt="..."
                                        className="w-100 h-100"/>
                                </Link>
                            </div>
                            <div className="doc-details">
                                <Link to={`/doctor-profile/${team.id}`}>
                                    <div className="doc-name">
                                       {team.name}
                                        <span className="degree"> ({team.department})</span>
                                    </div>
                                </Link>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="info-bar">
                                            <div className="info-head">Consultation Time </div>
                                            <div>&nbsp; : &nbsp;</div>
                                            <div className="info-content flex-fill">{team.consultation_time? team.consultation_time:'Not availale'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="info-bar">
                                            <div className="info-head">Consultation Fee </div>
                                            <div>&nbsp; : &nbsp;</div>
                                            <div className="info-content flex-fill">{ team.consultation_fee? 'Rs. '+team.consultation_fee: 'Not availale'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="info-bar">
                                            <div className="info-head">Mobile Number </div>
                                            <div>&nbsp; : &nbsp;</div>
                                            <div className="info-content flex-fill">{team.mobile_number ? team.mobile_number :'Not availale'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="info-bar">
                                            <div className="info-head">E-Mail ID </div>
                                            <div>&nbsp; : &nbsp;</div>
                                            <div className="info-content flex-fill"> {team.email_id? team.email_id:'Not availale'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                                    )}
                      
                    </div>
                </div>
            </div>
        </div>
    </section>
            
        </>
    )
  }
}
