export default class ConstantUrl{
    // publicUrl = "https://amokhaadmin.venturingdigitally.co.in/";
    publicUrl = "https://admin.amokhaherbal.com/";
    // publicUrl = "http://127.0.0.1:8000/";
    categoryPhotoUrl = this.publicUrl + "uploads/category/";
    brandPhotoUrl = this.publicUrl + "uploads/brands/";
    homesliderPhotoUrl = this.publicUrl + "uploads/homesliders/";
    bannerPhotoUrl = this.publicUrl + "uploads/banners/";
    blogsPhotoUrl = this.publicUrl + "uploads/blogs/";
    healthPhotoUrl = this.publicUrl + "uploads/health/";
    ResearchArticlePhotoUrl = this.publicUrl + "uploads/ResearchArticle/";
    productsPhotoUrl = this.publicUrl + "uploads/products/";
    teamsPhotoUrl = this.publicUrl + "uploads/teams/";
    herbPhotoUrl = this.publicUrl + "uploads/getHerb/";


    Api = "api/"
    RestApi = this.publicUrl + this.Api;
    categoryAll = this.RestApi+"categorie"; 
    brandsAll = this.RestApi+"brands"; 
    blogsAll = this.RestApi+"blogs"; 
    homeLibrary = this.RestApi+"getHealthLibrary";
    healthDetails = this.RestApi+"getHealthLibraryById"; 
    article = this.RestApi+"getResearchArticles"; 
    articleDetails = this.RestApi+"getResearchArticlesById";   
    herb = this.RestApi+"getHerb"; 
    herbDetails = this.RestApi+"getHerbById"; 
    homeslidersAll = this.RestApi+"homesliders"; 
    productAll = this.RestApi+"allProduct"; 
    bannerAll = this.RestApi+"banners";
    settingData = this.RestApi+"getData"; 
    teamsAll = this.RestApi+"doctors"; 
    searchTeam = this.RestApi+"searchDoctor/"; 
    blogItemdata = this.RestApi+"blogById"; 
    productDetails = this.RestApi+"productDetailsById"; 
    doctorDetails = this.RestApi+"getTeamsdetails"; 
    addDoctor = this.RestApi+"addDoctor"; 
    registerUser = this.RestApi+"userregister"; 
    loginUser = this.RestApi+"login"; 
    forgetPassword = this.RestApi+"forgotPassword"; 
    cartget = this.RestApi+"getCart"; 
    cartAdd = this.RestApi+"addCart"; 
    cartQuantityAdd = this.RestApi+"addCartQuantity"; 
    cartQuantityRemove = this.RestApi+"removeCartQuantity"; 
    submitOrder = this.RestApi+"submitOrderData"; 
    cartdeleteItem = this.RestApi+"deleteCartItem"; 
    orderAllHistory = this.RestApi+"getOrderData"; 
    getProductByCategoryId = this.RestApi+"getProductByCategorieId"; 
    getProductByBrandId = this.RestApi+"getProductByBrandId"; 
    ordercreateforreazorpay = this.RestApi+"createRazorpayOrder"; 
    profileUpdate = this.RestApi+"profileupdate"; 
    searchProduct = this.RestApi+"search/"; 
}