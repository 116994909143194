import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ConstantUrl from "../util/ConstantUrl";

export default function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorShow, setErrorShow] = useState("");

  const URL = new ConstantUrl();
  const navigate = useNavigate();

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isOtpVerified) {
      toast("Please verify OTP before proceeding.", {
        type: "error",
        theme: "dark",
      });
      return;
    }
    if (password !== cpassword) {
      setIsError(true);
      setErrorShow("Passwords do not match.");
      return;
    }
    submitForm();
  };

  // Send static OTP
  const sendOtp = (e) => {
    e.preventDefault();
    const staticOtp = "123456"; // Static OTP for testing
    setOtp(staticOtp);
    setIsOtpSent(true);
    toast(`Static OTP is ${staticOtp}.`, { type: "info", theme: "dark" });
  };

  // Verify static OTP
  const verifyOtp = (e) => {
    e.preventDefault();
    if (otp === "123456") {
      setIsOtpVerified(true);
      toast("OTP verified successfully.", { type: "success", theme: "dark" });
      setIsError(false);
      setErrorShow("");
    } else {
      setIsError(true);
      setErrorShow("Invalid OTP. Please try again.");
    }
  };

  // Submit registration form
  const submitForm = async () => {
    try {
      const response = await axios.post(URL.registerUser, {
        fullname: name,
        email: email,
        phone: phone,
        password: password,
      });
      const data = response.data["data"];
      if (response.data["error"]) {
        setErrorShow(data);
        setIsError(true);
      } else {
        navigate("/login", { replace: true });
        toast("Registered successfully.", { type: "success", theme: "dark" });
      }
    } catch (error) {
      toast("An error occurred during registration.", {
        type: "error",
        theme: "dark",
      });
    }
  };

  return (
    <>
      <section id="breadcrumb">
        <div className="container-fluid">
          <div className="container">
            <div className="breadcrumb-bar">
              <div>
                <Link to="/home">Home</Link>
              </div>
              <div>
                &nbsp; <i className="fa-solid fa-minus"></i> &nbsp;
              </div>
              <div>Register</div>
            </div>
          </div>
        </div>
        <hr className="m-0" />
      </section>

      <section id="login-area">
        <div className="section-gap">
          <div className="container-fluid">
            <div className="container">
              <div className="login-block">
                <div className="row">
                  <div className="col-lg-6 my-auto">
                    <div className="login-content">
                      <div className="content-head">Welcome!</div>
                      <div className="content-slogan">Amogh Ayurved</div>
                      <div className="content-data">
                        Amogh Ayurved is an Online Store to buy 100% Natural
                        Herbal Tablets in India. The best remedy for healthy
                        life.
                      </div>
                      <div className="redirect">
                        Already have an account?{" "}
                        <Link to="/login">Login Here</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="login-form">
                      <div className="form-head">Register</div>
                      <div className="form">
                        {!isOtpSent ? (
                          <form onSubmit={sendOtp}>
                            <div className="group-input">
                              <label htmlFor="name">Name</label>
                              <input
                                type="text"
                                name="name"
                                value={name}
                                id="name"
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter your name"
                                required
                              />
                            </div>
                            <div className="group-input">
                              <label htmlFor="phone">Phone Number</label>
                              <input
                                 type="number"
                                 name="phone"
                                 value={phone}
                                 id="phone"
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder="Enter your phone"
                                required
                              />
                            </div>
                            <div className="group-input">
                              <label htmlFor="email">E-Mail</label>
                              <input
                                type="email"
                                name="email"
                                value={email}
                                id="email"
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                                required
                              />
                            </div>
                            {isError && (
                              <p className="text-danger">{errorShow}</p>
                            )}
                            <div className="group-input">
                              <input
                                type="submit"
                                value="Send OTP"
                                className="submit"
                              />
                            </div>
                          </form>
                        ) : !isOtpVerified ? (
                          <form onSubmit={verifyOtp}>
                            <div className="group-input">
                              <label htmlFor="otp">OTP</label>
                              <input
                                type="text"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                placeholder="Enter OTP"
                                required
                              />
                            </div>
                            {isError && (
                              <p className="text-danger">{errorShow}</p>
                            )}
                            <div className="group-input">
                              <input
                                type="submit"
                                value="Verify OTP"
                                className="submit"
                              />
                            </div>
                          </form>
                        ) : (
                          <form onSubmit={handleSubmit}>
                            <div className="group-input">
                              <label htmlFor="password">Password</label>
                              <input
                                type="password"
                                name="password"
                                value={password}
                                id="password"
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter password"
                                required
                              />
                            </div>
                            <div className="group-input">
                              <label htmlFor="cpassword">
                                Confirm Password
                              </label>
                              <input
                                 type="password"
                                 name="cpassword"
                                 value={cpassword}
                                 id="cpassword"
                                onChange={(e) => setCpassword(e.target.value)}
                                placeholder="Confirm password"
                                required
                              />
                            </div>
                            {isError && (
                              <p className="text-danger">{errorShow}</p>
                            )}
                            <div className="group-input">
                              <input
                                type="submit"
                                value="Sign Up"
                                className="submit"
                              />
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
//comment