import axios from 'axios';
import { Parser } from 'html-to-react';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import ConstantUrl from '../util/ConstantUrl';

export default class ContactUs extends Component {

     state = {
        aboutdata:[]
        };
        

   async componentDidMount() {

        
         const URL = new ConstantUrl();
        
      await  axios.post(URL.settingData,{data:'contactus'}).then((response) => {
            
           const aboutdata = response.data['data'];
         console.warn(aboutdata);
          this.setState({ aboutdata:aboutdata[0] });
       
    });

        
    }

  render() {
    return (
        <>
        
              {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
    <section id="breadcrumb">
        <div className="container-fluid">
            <div className="container">
                <div className="breadcrumb-bar">
                    <div><Link to="/home">Home</Link></div>
                    <div>&nbsp; <i className="fa-solid fa-minus"></i> &nbsp;</div>
                    <div>Contact Us</div>
                </div>
            </div>
        </div>
        <hr className="m-0"/>
    </section>

    {/* {{-- =============================================
                    CONTACT AREA
    ============================================  --}} */}
    <section id="contact-area">
        <div className="section-gap">
            <div className="container-fluid">
                <div className="container">
                    <div className="map-area">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.3485408752354!2d78.61251621493916!3d25.460034683774552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39777656c80c7aef%3A0x9467d58b85ef03f9!2sMaharani%20Laxmi%20Bai%20Medical%20College!5e0!3m2!1sen!2sin!4v1673420572818!5m2!1sen!2sin"
                             loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                            className="w-100 h-100" title='map'>
                        </iframe>
                    </div>
                    <div className="contact-grid">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 order-lg-1 order-1">
                                <div className="contact-card">
                                    <div className="contact-icon">
                                        <i className="fa-solid fa-phone"></i>
                                    </div>
                                    <div className="card-content">
                                        <a href="tel:+91 7887246298">+91 7887246298, 8542847236 </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 order-lg-2 order-3">
                                <div className="contact-card">
                                    <div className="contact-icon">
                                        <i className="fa-solid fa-signs-post"></i>
                                    </div>
                                    <div className="card-content">
                                        Shiv Colony, Maharana Pratap, Nagar, Near MLB College, Jhansi, U.P - 284001
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 order-lg-3 order-2">
                                <div className="contact-card">
                                    <div className="contact-icon">
                                        <i className="fa-regular fa-paper-plane"></i>
                                    </div>
                                    <div className="card-content">
                                        <a href="mailto:info@amokhaherbal.com">info@amokhaherbal.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-area">
                        <div className="row">
                            <div className="col-lg-12 my-auto">
                                <div className="form-content">
                                    <div className="content-head">Contact Us</div>
                                    <div className="content-slogan">Amogh Ayurved</div>
                                    <div className="content-data">
                                       {Parser().parse(this.state.aboutdata.value)}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-6">
                                <div className="form-block">
                                    <div className="form-head">
                                        Have any Query?
                                    </div>
                                    <div className="form-fields">
                                        <div className="group-input">
                                            <input type="text" name="name" id="name" placeholder="Full Name"/>
                                        </div>
                                        <div className="group-input">
                                            <input type="email" name="email" id="email" placeholder="E-Mail"/>
                                        </div>
                                        <div className="group-input">
                                            <textarea name="subject" id="subject" placeholder="What can we help you with?"></textarea>
                                        </div>
                                    </div>
                                    <div className="form-btn">
                                        <div className="group-input">
                                            <input type="submit" value="Login" className="submit"/>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
            
        </>
    )
  }
}
